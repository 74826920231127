<template>
    <div class="smsSettings">
        <div class="ml-20 mt-20 mb-20">

          <div class="name">
        <span @click="$router.back(-1)" :style="{cursor:'pointer',fontSize:'15px'}">
          <i class="el-icon-arrow-left">返回</i>
        </span>
        <span :style="{marginLeft:'40px',fontSize:'18px',fontWeight:'550'}">{{amountName}}</span>
      </div>
        </div>
        <div class="flex-nowrap-space-around-other">
            <div class="input-searchFrom-width-600">
                <el-form ref="upform" size="small" :model="upform" label-width="120px" :rules="rules">
                  <el-form-item label="用户：" prop="uid">
                  <el-select @change="ReloadBranchNumberError" v-model="upform.uid" filterable placeholder="请选择"
                             style="width: 100%">
                    <el-option v-for="(item, index) in user_list" :key="index"
                               :label="`${item.nickname||''}--${item.company||''}--(Id:${item.uid})`" :value="item.uid">
                      <div class="flex-nowrap-space-between">
                        <div :class="item.status == 1?'no-red':''">
                          <span>{{ item.nickname }}--</span>
                          <span>{{ item.company }}--</span>
                          <span class="ml-5">(Id:{{ item.uid }})</span>
                        </div>
                        <div v-if="item.status == 1" class="no-red">禁</div>
                      </div>
                    </el-option>
                  </el-select>
                </el-form-item>
                    <el-form-item label="接口账户：" prop="interfaceId">
                        <el-select
                            v-model="upform.interfaceId"
                            placeholder="请选择查询的接口账户"
                            style="width: 100%"
                            clearable
                            filterable 
                            >
                            <el-option
                                v-for="(item, index) in listArr"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="号码列表：" prop="number">
                        <el-input
                            v-model="upform.number"
                            type="textarea"
                            placeholder="请输入手机号(换行可输入多个)"
                            :autosize="{ minRows: 6, maxRows: 8 }"
                        />
                        <div>一行一个，格式：主叫号码-小号，例如：15212345678-18812345678</div>
                    </el-form-item>
                    <el-form-item label="">
                        <el-button class="yes-green-bg" @click="theQuery('upform')">增 加</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="input-searchFrom-width-600 show-boxs">
                <div v-if="dataList.length == 0">
                    <el-empty description="暂无数据"></el-empty>
                </div>
                <div v-else>
                    <div v-for="(item,i) in dataList"  :key="i" class="flex-nowrap-space-around item-box-other">
                        <div>{{ item.num }}</div>
                        <div>{{ item.error }}</div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import ctcc from '@/api/open/ctcc'
import interfacets from '@/api/open/privacy/interface'
import userts from '@/api/web/user'

export default {
    components: {

    },
    data () {
        const checkNumber = (rule, value, callback) => {
            let num = 0,types = null,errorItem;
            if(value == null){
                callback(new Error('请填写号码 (多个号码请换行输入)'))
            }else if(value.length == 0){
                callback(new Error('请填写号码 (多个号码请换行输入)'))
            }else{
                let newArr = []
                newArr = value.split(/[(\r\n)\r\n]+/)
                newArr.forEach((item,i)=>{
                    if(item.match(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9]|9[0-9]|0[0-9][0-9]|2[0-9]|0[0-9][0-9][0-9])\d{8}$/) == null){
                    // if(item.match(/^(1[3|4|5|6|7|8|9])\\d{9}$|^0\\d{2,3}-?\\d{7,8}$|^9\\d{9}/) == null){
                        // callback(new Error('请输入正确的号码 (请检查第'+(i+1)+"行)"))
                        num++
                        types = i
                        errorItem = item
                    }
                })
                if( num != 0 ){
                    callback(new Error('请输入正确的号码 (请检查第'+(types+1)+"行："+ errorItem +")"))
                }else{
                    callback()
                }
            }
        }
        return {
          user_list: [],
            upform:{},
            listArr:[{
                name:'XZ电信回拨',
                id:3
            },{
                name:'DF电信回拨',
                id:13
            }],
            dataList:[],
            rules: {
              uid: 
                {
                  required: true,
                  message: '请选择用户',
                  trigger: 'change',
                },
                interfaceId: [
                    {
                    required: true,
                    message: '请选择查询的接口账户',
                    trigger: 'change',
                    },
                ],
                // number: [
                //     { required: true, validator: checkNumber, trigger: 'blur' },
                // ],
            }
        }
    },
    created() {
        this.getApiType()
        this.getUserList()
    },
    watch: {

    },
    mounted() {

    },
    methods: {

          // 获取接口类型数组    // 获取用户列表
    getUserList() {
      userts.queryList({}).then((res) => {
        this.user_list = res.data
      })
    },
    getApiType() {
      interfacets.seeApiList({}).then((res) => {
        this.listArr = res.data
      })
    },
       theQuery(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let upArr = []
                    let arrLest = this.upform.number.split(/[(\r\n)\r\n]+/)
                    this.unique(arrLest).forEach((item,i) =>{
                        upArr.push(item.trim())
                    })
                    ctcc.addHunanCallBack({
                        interfaceId:this.upform.interfaceId,
                        uid:this.upform.uid,
                        nums:upArr
                    }).then(res => {
                        if(res.code == 200){
                          this.$message.success('成功');

                            this.dataList = res.data
                        }
                    })
                } else {
                console.log('error submit!!')
                return false
                }
            })
        },
        unique(arr) {
            const res = new Map();
            return arr.filter((a) => !res.has(a) && res.set(a, 1))
        },
    }
}
</script>

<style lang="scss" scoped>
    // .smsSettings{
    //     // margin-top: 60px;
    // }
    .show-boxs{
        height: 100%;
        border: 1px solid #0000;
    }
    .item-box-other{
        padding: 8px 16px;
        margin-bottom: 10px;
        border-radius: 4px;
        background: #ebebeb;
    }
</style>